/* eslint-disable max-len */
import React, { Component } from 'react';
import {
  Button,
  Row,
  Col,
  ListGroup,
  Container,
  Form,
  InputGroup,
} from 'react-bootstrap';
import moment from 'moment';
import Axios from 'axios';
import { notify } from 'react-notify-toast';
import Pagin from '../Pagin';
import BSModal from '../../Layout/BSModal';
import LoadingScreen from '../../Layout/LoadingScreen';
import AddItemForm from './AddItemForm';
import CloseRegister from './CloseRegister';
import SearchBox from './SearchBox';
import DownloadExcel from './DownloadExcel';

import ConfirmClose from './ConfirmClose';
import ModifyConfirm from './ModifyConfirm';

export default class Smallbox extends Component {
  constructor(i) {
    super(i);
    this.state = {
      fullData: [],
      data: [],
      pagedData: [],
      modalState: {
        download: false,
        add: false,
        view: false,
        movements: false,
        modify: false,
        modifyId: '',
        closeDay: false,
        delete: false,
        deleteId: '',
      },
      movements: [],

      editId: '',
      isSaving: false,
      concept: '',
      detail: '',
      code: '',
      amount: '',
      type: true,
      todayAmount: 3000,
      totalAmount: 3000,
      ivaAmount: 3000,
      iibbAmount: 3000,
      idcAmount: 3000,
      isLoading: true,
      income: false,
      outcome: false,
      date: '',
      filter: false,
      level: 0,
      filterAplicated: [],
      pageSize: 5,
      customers: []
    };

    this.HEADERCONFIG = {
      headers: { 'auth-token': localStorage.getItem('JWT') },
    };
  }

  componentDidMount() {
    this.getItems();
    // console.log(this.props, 'props')
  }

  getItems = () => {
    const smallboxAll = Axios.post('/api/smallbox/get', { currency: this.props.currency }, this.HEADERCONFIG);
    const moneyCount = Axios.post('api/smallbox/get/moneyCount', { currency: this.props.currency }, this.HEADERCONFIG);
    const customers = Axios.get('api/smallbox/getCustomers', this.HEADERCONFIG);

    Axios.all([smallboxAll, moneyCount, customers])
      .then(
        Axios.spread((...i) => {
          if (!i[0].data.success) {
            notify.show(i[0].data.message, 'error');
            this.props.history.push('/');
          }

          this.setState({
            data: i[0].data.data,
            fullData: i[0].data.data,
            ivaAmount: i[1].data.amount.iva,
            iibbAmount: i[1].data.amount.iibb,
            idcAmount: i[1].data.amount.idc,
            todayAmount: i[1].data.amount.today,
            totalAmount: i[1].data.amount.total,
            customers: i[2].data.data,
            level: 1,
            isLoading: false,
          });
        })
      )
      .catch((err) => {
        notify.show(err.message, 'error');
        this.props.history.push('/');
      });
  };

  getProjects = (clientName) => {
    if (clientName !== '') {
      const filteredRegions = this.state.customers.filter((client) => client.name === clientName);
      if (filteredRegions?.length !== 0) {
        return filteredRegions[0].projects.filter((project) => project.name);
      }
      return [];
    }
    return [];
  };

  onChangePage = (pagedData) => {
    this.setState({ pagedData });
  };

  customers = () => {
    const custs = [];
    this.state.fullData.map((i) => {
      custs.push({
        Creado: moment(i.createdAt).format('L LT'),
        Concepto: i.concept,
        Cliente: i.client,
        Código: i.code,
        Monto: i.amount,
        Tipo: i.type ? 'Ingreso' : 'Egreso',
        Detalle: i.detail,
      });

      return 0;
    });
    return custs;
  };

  saveCustomers = () => {
    this.setState({
      customers: this.customers(),
      fileName: 'Caja Chica',
    });
  };

  render() {
    const user = this.context;
    const { totalAmount, todayAmount, totalSearch, todaySearch, ivaSearch, iibbSearch, idcSearch, ivaAmount, iibbAmount, idcAmount } = this.state;

    const adminButtons =
      this.state.level === 1 ? (
        <>
          {/* <Button
            className="mr-1"
            variant="info"
            onClick={() =>
              this.setState((i) => ({
                modalState: { movements: !i.modalState.movements },
              }))}
          >
            <i className="fas fa-dot-circle mr-2" />
            Ver cierres anteriores
          </Button> */}
          <Button
            className="mr-1"
            variant="success"
            onClick={() =>
              this.setState((i) => ({
                modalState: { download: !i.modalState.download },
              }))}
            disabled
          >
            <i className="fas fa-dot-circle mr-2" />
            Descargar excel
          </Button>
        </>
      ) : null;

    if (this.state.isLoading) {
      return <LoadingScreen />;
    }

    return (
      <Container>
        <DownloadExcel
          show={this.state.modalState.download}
          onHide={() => {
            this.setState((i) => ({
              modalState: { ...i.modalState, download: !i.modalState.download },
            }));
          }}
        />

        <ModifyConfirm
          refresh={this.getItems}
          modifyId={this.state.modalState.modifyId}
          show={this.state.modalState.modify}
          onHide={() =>
            this.setState((i) => ({
              modalState: { ...i.modalState, modify: !i.modalState.modify },
            }))}
        />

        {/* <CloseRegister
          modifyAlert={(aidi) =>
            this.setState((i) => ({
              modalState: {
                ...i.modalState,
                modify: !i.modalState.modify,
                modifyId: aidi,
              },
            }))}
          id={this.state.modalState.modifyId}
          items={this.state.fullData}
          onHide={() =>
            this.setState((i) => ({
              modalState: {
                ...i.modalState,
                movements: !i.modalState.movements,
              },
            }))}
          show={this.state.modalState.movements}
        /> */}

        <AddItemForm
          editId={this.state.editId}
          customers={this.state.customers}
          getProjects={(clientName) => this.getProjects(clientName)}
          refresh={this.getItems}
          onHide={() =>
            this.setState((i) => ({
              modalState: { ...i.modalState, add: !i.modalState.add },
            }))}
          show={this.state.modalState.add}
          currency={this.props.currency}
        />

        <BSModal
          show={this.state.modalState.delete}
          handleClose={() => {
            this.setState((prevState) => ({
              modalState: {
                ...prevState.modalState,
                delete: !prevState.modalState.delete,
              },
            }));
          }}
          title="Advertencia"
          body="¿Seguro que querés eliminar el ítem?"
          variant="danger"
          action={() => {
            Axios.delete(
              `/api/smallbox/delete/${this.state.modalState.deleteId}/${this.props.currency}`,
              this.HEADERCONFIG
            ).then((res) => {
              this.setState((prevState) => ({
                modalState: {
                  ...prevState.modalState,
                  delete: !prevState.modalState.delete,
                },
              }));
              this.getItems();
              notify.show(res.data.message, 'success');
            });
          }}
        />

        {/* <ConfirmClose
          {...this.props}
          additionalData={{
            amountInternal: this.state.totalAmount,
            user: user.username,
          }}
          goHome={() => this.props.history.push('/')}
          show={this.state.modalState.closeDay}
          onHide={() => {
            this.setState((prevState) => ({
              modalState: {
                ...prevState.modalState,
                closeDay: !prevState.modalState.closeDay,
              },
            }));
          }}
        /> */}

        <Row>
          <Col
            className="shadow h-100 my-auto bg-white rounded p-3 py-4 text-center"
            md="4"
          >
            <Row>
              <Col className="text-left">
                <small>Monto (Total):</small>
                <h5 className="font-weight-bold">
                  $
                  {this.state.filterAplicated.length !== 0
                    ? parseFloat(totalSearch).toFixed(2)
                    : parseFloat(totalAmount).toFixed(2)}
                </h5>
              </Col>
              <Col className="text-right">
                <small>Monto (Iva):</small>
                <h5 className="font-weight-bold">
                  $
                  {this.state.filterAplicated.length !== 0
                    ? parseFloat(ivaSearch).toFixed(2)
                    : parseFloat(ivaAmount).toFixed(2)}
                </h5>
              </Col>
            </Row>
            <Row>
              <Col className="text-left">
                <small>Monto (Hoy):</small>
                <h5 className="font-weight-bold">
                  $
                  {this.state.filterAplicated.length !== 0
                    ? parseFloat(todaySearch).toFixed(2)
                    : parseFloat(todayAmount).toFixed(2)}
                </h5>
              </Col>
              <Col className="text-right">
                <small>Monto (IIBB):</small>
                <h5 className="font-weight-bold">
                  $
                  {this.state.filterAplicated.length !== 0
                    ? parseFloat(iibbSearch).toFixed(2)
                    : parseFloat(iibbAmount).toFixed(2)}
                </h5>
              </Col>
            </Row>
            <Row>
              <Col className="text-left">
                <small>Monto (Diferencia IVA):</small>
                <h5 className="font-weight-bold">
                  $
                  {this.state.filterAplicated.length !== 0
                    ? parseFloat(totalSearch - ivaSearch).toFixed(2)
                    : parseFloat(totalAmount - ivaAmount).toFixed(2)}
                </h5>
              </Col>
              <Col className="text-right">
                <small>Monto (IDC):</small>
                <h5 className="font-weight-bold">
                  $
                  {this.state.filterAplicated.length !== 0
                    ? parseFloat(idcSearch).toFixed(2)
                    : parseFloat(idcAmount).toFixed(2)}
                </h5>
              </Col>
            </Row>
            <Row>
              <Col className="text-left">
                <small>
                  <b>
                    {this.state.filterAplicated.length !== 0
                      ? `Filtros: ${this.state.filterAplicated}`
                      : null}
                  </b>
                </small>
              </Col>
            </Row>
          </Col>
          <Col />
          <Col
            md="auto"
            className="shadow bg-white rounded py-3 text-center my-auto"
          >
            <Button
              className="mx-1"
              variant="info"
              onClick={() => {
                this.setState((prevState) => ({
                  modalState: {
                    ...prevState.modalState,
                    add: !prevState.modalState.add,
                  },
                  editId: '',
                }));
              }}
            >
              <i className="fas fa-plus-circle mr-2" />
              Registrar movimiento
            </Button>

            {/* <Button
              className="mx-1"
              variant="danger"
              onClick={() =>
                this.setState((i) => ({
                  modalState: { closeDay: !i.modalState.closeDay },
                }))}
            >
              <i className="fas fa-times-circle mr-2" />
              Cerrar caja
            </Button> */}

            {adminButtons}

            <Form.Group as={Col} className="pt-3 pl-0 pr-0 mb-0">
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text>Items por página:</InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  as="select"
                  onChange={(e) =>
                    this.setState({ pageSize: e.target.value }, () =>
                      this.getItems()
                    )}
                  name="pageSize"
                >
                  <option value={5}>5</option>
                  <option value={10}>10</option>
                  <option value={25}>25</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                </Form.Control>
              </InputGroup>
            </Form.Group>
          </Col>
        </Row>

        {/* Formulario de búsqueda */}

        <SearchBox
          refresh={this.getItems}
          setResults={(e) =>
            this.setState({
              data: e.data,
              filterAplicated: e.filter,
              totalSearch: e.amount.total,
              todaySearch: e.amount.today,
              ivaSearch: e.amount.iva,
              iibbSearch: e.amount.iibb,
              idcSearch: e.amount.idc,
            })}
          customers={this.state.customers}
          currency={this.props.currency}
        />

        {
          this.state.data.length > 0 ? (
            <Row>
              <Col className="p-3 my-3 bg-light rounded shadow">
                <Row className="px-4 pb-3">
                  <Col md="1">Fecha</Col>
                  <Col md="2">Creador / Concepto</Col>
                  <Col md="2">Cliente</Col>
                  <Col md="2">Código</Col>
                  <Col md="1">Monto</Col>
                  <Col md="1">Tipo</Col>
                  <Col md="2">Notas adicionales</Col>
                  <Col md="1" />
                </Row>
                <ListGroup as="ul">

                  {this.state.pagedData.map((i, a) => (
                    <ListGroup.Item action as="li" key={a}>
                      <Row>
                        <Col md="1">
                          <span className="m-0 p-0 text-truncate">
                            {moment.utc(i.date).format('L')}
                          </span>
                        </Col>
                        <Col
                          md="2"
                          className="text-truncate"
                          style={{ width: '%100' }}
                        >
                          <span
                            className="m-0 p-0"
                            style={{ borderBottom: 'none' }}
                          >
                            <abbr
                              title={
                                typeof i.createdBy === 'object'
                                  ? `${i.createdBy.name} / ${i.concept}`
                                  : `${i.createdBy} / ${i.concept}`
                              }
                            >
                              {i.concept}
                            </abbr>
                          </span>
                        </Col>
                        <Col md="2">
                          <span className="m-0 p-0">{i.client}</span>
                        </Col>
                        <Col md="2">
                          <span className="m-0 p-0">{i.code}</span>
                        </Col>
                        <Col md="1" className="text-truncate" style={{ width: '%100' }}>
                          <span className={`${i.type ? 'text-success' : 'text-danger'} m-0 p-0`}>
                            <abbr title={i.amount}>
                              $
                              {i.amount}
                            </abbr>
                          </span>
                        </Col>
                        <Col md="1">
                          <span className="m-0 p-0">
                            {i.type ? 'Ingreso' : 'Egreso'}
                          </span>
                        </Col>
                        <Col
                          md="2"
                          className="text-truncate"
                          style={{ width: '%100' }}
                        >
                          <span className="m-0 p-0">
                            <abbr title={i.detail}>{i.detail}</abbr>
                          </span>
                        </Col>
                        <Col md={1}>
                          <i
                            onClick={() => {
                              this.setState((a) => ({
                                editId: i._id,
                                modalState: {
                                  ...a.modalState,
                                  add: !a.modalState.add,
                                },
                              }));
                            }}
                            style={{ cursor: 'pointer' }}
                            className="fas fa-edit p-1"
                            alt="Editar"
                          />
                          <i
                            onClick={() =>
                              this.setState({
                                modalState: {
                                  deleteId: i._id,
                                  delete: true,
                                },
                              })}
                            style={{ cursor: 'pointer' }}
                            className="fas fa-trash p-1 text-danger"
                            alt="Eliminar"
                          />
                        </Col>
                      </Row>
                    </ListGroup.Item>
                  ))}

                </ListGroup>
              </Col>
            </Row>
          )
            : (
              <>
                <Row>
                  <Col className="p-3 my-3 bg-light rounded shadow pt-4 pb-4 d-flex justify-content-center">
                    <span className="m-0 p-0">No existe ningún registro!</span>
                  </Col>
                </Row>
              </>
            )
        }

        <Row>
          <Col className="p-3 mb-3 bg-light rounded shadow d-flex justify-content-center">
            <Pagin
              items={this.state.data}
              onChangePage={this.onChangePage}
              pageSize={this.state.pageSize}
            />
          </Col>
        </Row>
      </Container>
    );
  }
}
