import React from 'react';
import { Container, Tabs, Tab } from 'react-bootstrap';
import Smallbox from './Smallbox';
import USDSmallbox from './USDSmallbox';

export default function Graphics(props) {
  const tabs = [{ eventKey: 'ars', title: 'Pesos ARS', currency: 'ARS' }, { eventKey: 'usd', title: 'Dolar USD', currency: 'USD' }];
  // console.log(props, 'historial');
  return (
    <Container className="mb-5">
      <Tabs id="tabs" defaultActiveKey="ars" className="border-0">
        {tabs.map((currency) => (
          <Tab eventKey={currency.eventKey} title={currency.title}>
            <Smallbox currency={currency.currency} history={props.history} />
          </Tab>
        ))}
      </Tabs>
    </Container>
  );
}
