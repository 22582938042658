import React from 'react';
import Notifications from 'react-notify-toast';
import NavigationBar from './components/NavigationBar/index';
import Routing from './components/Routing';

function App() {
  return (
    <>
      <Notifications options={{ zIndex: 9999 }} />
      <NavigationBar />
      <Routing />
    </>
  );
}

export default App;
