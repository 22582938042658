/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Form, ListGroup } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { notify } from 'react-notify-toast';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { FORM } from './constants';
import CustomInput from '../../../components/CustomInput';
import Project from '../projects/Project';
import DeleteProject from '../projects/DeleteProject';
import Selector from './Selector';
import AddClient from './AddClient';
import ConfirmDelete from './ConfirmDelete';

export default function Clients() {
  const { register, watch, handleSubmit, setValue } = useForm();
  const history = useHistory();
  const headerConfig = {
    headers: { 'auth-token': localStorage.getItem('JWT') },
  };
  const [showProject, setShowProject] = useState(false);
  const [showAddClient, setShowAddClient] = useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [showConfirmDeleteProject, setShowConfirmDeleteProject] = useState(false);
  const [isLoading, setIsLoading] = useState({
    component: true,
    singleClient: false,
  });
  const [selectedCommission, setSelectedClient] = useState('');
  const [client, setClient] = useState({});
  const [projectEdit, setProjectEdit] = useState({});
  const [indexEdit, setIndexEdit] = useState(0);
  const [projectDelete, setProjectDelete] = useState([]);
  const [clientList, setClientList] = useState([]);
  const [clientListLoading, setClientListLoading] = useState(true);
  const [edit, setEdit] = useState(false);
  const onError = (errors, e) => console.log(errors, e);

  let counter = 0;

  useEffect(() => {
    if (counter === 0) {
      bringData();
      // eslint-disable-next-line no-plusplus
      ++counter;
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setClientListLoading(false);
  }, [clientList]);

  const updateClient = () => {
    const copyOfWatch = watch();
    copyOfWatch.projects = client.projects;
    axios
      .put(`/api/client/${client._id}`, copyOfWatch, headerConfig)
      .then(({ data }) => {
        if (data.success) {
          notify.show(data.message, 'success');
        } else {
          notify.show(data.message, 'error');
        }
      })
      .catch((err) => {
        notify.show(err.response.data.message, 'error');
        history.push('/');
      });
  };

  const bringData = () => {
    const client = axios.get('/api/client/', headerConfig);

    axios
      .all([client])
      .then(
        axios.spread((...response) => {
          if (response[0].data.success) {
            setClient({ _id: '' });
            setIsLoading({ ...isLoading, component: false });
            if (response[0].data) {
              if (response[0].data.data) {
                setClientList(response[0].data.data.filter((i) => i.name !== undefined));
              } else {
                setClientList(response[0].data.filter((i) => i.name !== undefined));
              }
            }
          } else {
            notify.show(response.message, 'error');
            history.push('/');
          }
        })
      )
      .catch((err) => {
        notify.show(err.message, 'error');
        history.push('/');
      });
  };

  // Manejo del cambio de cliente (recibe de Selector)
  const handleClientChange = (obj) => {
    if (!obj) {
      setClientValues(selectedCommission);
    } else {
      const { value } = obj.target;
      setSelectedClient(value);
      setIsLoading({ ...isLoading, singleClient: true });

      setClientValues(value);
    }
  };

  // Setea todos los valores del cliente seleccionado
  const setClientValues = (clientId) => {
    axios
      .get(`/api/client/${clientId}`, headerConfig)
      .then(({ data }) => {
        setIsLoading({ ...isLoading, singleClient: false });
        setClient({
          ...data.data[0],
        });
      })
      .catch((err) => {
        notify.show(err.response.data.message, 'error');
        history.push('/');
      });
  };

  useEffect(() => {
    // eslint-disable-next-line array-callback-return
    Object.keys(client).map((key) => {
      if (key !== 'projects' && key !== '_id') {
        setValue(key, client[key]);
      }
    });
  }, [client]);

  useEffect(() => {
    console.log(projectEdit, 'pe');
  }, [projectEdit]);

  const takeProject = (a) => {
    setProjectEdit(client.projects[a]);
    setIndexEdit(a);
  };

  const deleteProject = (a) => {
    // eslint-disable-next-line prefer-const
    let copyOfProjects = client.projects.slice(0);
    delete copyOfProjects[a];
    setProjectDelete(copyOfProjects.filter(Boolean));
  };

  return (
    <>
      <Project
        client={client}
        setClient={setClient}
        show={showProject}
        onHide={(projectContent) => { setShowProject(!showProject); setProjectEdit(projectContent); console.log(client.projects); }}
        edit={edit}
        setEdit={(value) => setEdit(value)}
        indexEdit={indexEdit}
        projectEdit={projectEdit}
        setProjectEdit={(projectContent) => setProjectEdit(projectContent)}
      />
      <DeleteProject
        client={client}
        show={showConfirmDeleteProject}
        onHide={() => setShowConfirmDeleteProject(!showConfirmDeleteProject)}
        projectDelete={projectDelete}
        updateClient={() => setClient({ ...client, projects: projectDelete })}
      />
      <AddClient
        show={showAddClient}
        onHide={() => setShowAddClient(!showAddClient)}
        updateClient={bringData}
      />
      <ConfirmDelete
        show={showConfirmDelete}
        onHide={() => setShowConfirmDelete(!showConfirmDelete)}
        idToDelete={client._id}
        updateClient={bringData}
        deleteClient={() => setClient({
          name: '',
          email: '',
          phone: '',
          isActive: '',
          projects: [],
        })}
      />
      <Selector
        clientSelected={(obj) => handleClientChange(obj)}
        client={client !== [] ? client : { students: false }}
        confirmDelete={() => setShowConfirmDelete(!showConfirmDelete)}
        addClient={() => setShowAddClient(!showAddClient)}
        isLoading={isLoading}
        clientList={clientList}
        clientListLoading={clientListLoading}
        setClientListLoading={(value) => setClientListLoading(value)}
      />

      <Container
        className="py-3 my-3 bg-light rounded shadow"
      >
        <p className="lead">Configuración de los clientes</p>
        <Form onSubmit={handleSubmit(updateClient, onError)}>

          <Row className="w-100">
            {
              FORM.map((form) => (
                <Col sm="12" md={form.extended ? '12' : '4'}>
                  <CustomInput
                    id={form.id}
                    title={form.title}
                    name={form.name}
                    type={form.type}
                    placeholder={form.placeholder}
                    customText={form.customText}
                    register={register}
                    watch={watch}
                    options={form.options}
                    as={form.as}
                  />
                </Col>
              ))
            }
          </Row>

          <Row>
            <Col>
              <Row>
                <Col sm={11}>
                  <label>Proyectos</label>
                </Col>
                <Col>
                  <Button variant="info" onClick={() => setShowProject(true)}>
                    <i className="fas fa-plus" />
                  </Button>
                </Col>
              </Row>
              <ListGroup as="ul" style={{ height: 250, overflowY: 'scroll' }} className="mt-2">
                {client.projects ? (
                  client.projects.length === 0 ? (
                    <ListGroup.Item action as="li">
                      <i className="text-muted m-0 p-0">
                        Este cliente no tiene proyectos asignados
                      </i>
                    </ListGroup.Item>
                  ) : (
                    client.projects.map((i, a) => (
                      <ListGroup.Item
                        action
                        as="li"
                        key={a}
                      >
                        <Row>
                          <Col md={10}>
                            <span className="m-0 p-0">
                              {`${i.name} - ${moment(i.startDate).format('DD/MM/YYYY')}`}
                            </span>
                          </Col>
                          <Col>
                            <i
                              className="ml-2 text-dark far fa-edit"
                              style={{ cursor: 'pointer' }}
                              onClick={() => {
                                setEdit(true);
                                setShowProject(true);
                                takeProject(a);
                              }}
                            />
                          </Col>
                          <Col>
                            <i
                              className="ml-2 text-danger far fa-trash-alt"
                              style={{ cursor: 'pointer' }}
                              onClick={() => {
                                setShowConfirmDeleteProject(true);
                                deleteProject(a);
                              }}
                            />
                          </Col>
                        </Row>
                      </ListGroup.Item>
                    ))
                  )
                ) : (
                  <ListGroup.Item action as="li">
                    <i className="text-muted m-0 p-0">
                      Seleccione un cliente para continuar
                    </i>
                  </ListGroup.Item>
                )}
              </ListGroup>
            </Col>
          </Row>

          <Button
            variant="info"
            type="submit"
          // disabled={!client.students}
          >
            <i className="fas fa-chevron-circle-up mr-2" />
            Actualizar datos
          </Button>
        </Form>
      </Container>
    </>
  );
}
