import Axios from 'axios';
import { notify } from 'react-notify-toast';

export const exportToCSV = (onHide, itemsToSend, goHome) => {
  Axios.post('/api/mail/smallBoxReceipt', {
    user: itemsToSend.user,
    difference: itemsToSend.difference,
    amountInternal: itemsToSend.amountInternal,
    amountExternal: itemsToSend.amountExternal,
    notes: itemsToSend.notes,
    todayData: itemsToSend.todayData,
    createdAt: itemsToSend.createdAt,
    email: process.env.MAILTO,
  }, { headers: { 'auth-token': localStorage.getItem('JWT') } }).then(({ data }) => {
    if (data.success) {
      notify.show(data.message, 'success');
      goHome();
      onHide();
    } else {
      notify.show(data.message, 'error');
      onHide();
      goHome();
    }
  });
};
