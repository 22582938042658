import React, { useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import axios from 'axios';
import { notify } from 'react-notify-toast';

export default function AddClient({ show, onHide, updateClient }) {
  const [clientName, setClientName] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const onSubmit = (e) => {
    e.preventDefault();
    setIsSaving(!isSaving);

    const emptyClient = {
      name: clientName,
      email: '',
      phone: '',
      isActive: 'active',
      projects: [],
    };

    axios
      .post('/api/client/', emptyClient, {
        headers: { 'auth-token': localStorage.getItem('JWT') },
      })
      .then(({ data }) => {
        if (data.success) {
          notify.show('El cliente se agregó correctamente', 'success');
          setIsSaving(!isSaving);
          setClientName('');
          onHide();
          updateClient();
        } else {
          notify.show(data.message, 'error');
          setIsSaving(!isSaving);
        }
      })
      .catch((err) => {
        notify.show('Error al agregar el cliente', 'error');
      });
  };

  const handleChange = (e) => {
    const { value } = e.target;
    setClientName(value);
  };

  return (
    <Modal centered show={show} onHide={onHide}>
      <Form onSubmit={onSubmit}>
        <Modal.Body>
          <Form.Row className="pt-3">
            <Form.Label>Ingrese el nombre del cliente</Form.Label>
            <Form.Control
              type="text"
              name="clientName"
              onChange={handleChange}
            />
          </Form.Row>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={onHide}>
            Cerrar
          </Button>
          <Button disabled={isSaving} variant="info" type="submit">
            Guardar cambios
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
