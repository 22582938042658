import React, { Component } from 'react';
import {
  Modal,
  Button,
  Accordion,
  Card,
  ListGroup,
  Row,
  Col,
} from 'react-bootstrap';
import moment from 'moment';
import Axios from 'axios';
// import { UserContext } from '../../Context/UserContext';

export default class CloseRegister extends Component {
  // static contextType = UserContext;

  constructor(props) {
    super(props);

    this.state = {
      closedRegister: [],
      userData: [],
    };
  }

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    const getCloseMovements = Axios.get('/api/smallbox/getCloseMovements', {
      headers: { 'auth-token': localStorage.getItem('JWT') },
    });

    const getUserList = Axios.get('/api/user/getUserList', {
      headers: { 'auth-token': localStorage.getItem('JWT') },
    });

    Axios.all([getCloseMovements, getUserList])
      .then(
        Axios.spread((...responses) => {
          this.setState({
            closedRegister: responses[0].data.data,
            userData: responses[1].data.data,
          });
        })
      )
      .catch((err) => {});
  };

  render() {
    return (
      <Modal
        centered
        size="xl"
        show={this.props.show}
        onHide={this.props.onHide}
      >
        <Modal.Header closeButton scrollable>
          <Modal.Title>Movimientos de cierre</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Accordion>
            <Card>
              <Row className="p-3">
                <Col className="">Fecha y hora</Col>
                <Col className="text-center" md={1}>
                  Sistema
                </Col>
                <Col className="text-center" md={1}>
                  Usuario
                </Col>
                <Col className="text-center" md={2}>
                  Diferencia
                </Col>
                <Col className="text-center" md={1}>
                  Editar
                </Col>
              </Row>
              {this.state.closedRegister.map((i, a) => {
                const user = this.state.userData.find(
                  (item) => item._id === i.boxData[0].userData
                );

                return (
                  <>
                    <Accordion.Toggle key={a} as={Card.Header} eventKey={a}>
                      <Row>
                        <Col>
                          {moment(i.createdAt).format('LLLL')}
                          {' '}
                          /
                          {' '}
                          {user ? user.name : 'Usuario no encontrado'}
                        </Col>
                        <Col className="text-center" md={1}>
                          $
                          {i.boxData[0].amountInternal}
                        </Col>
                        <Col className="text-center" md={1}>
                          $
                          {i.boxData[0].amountExternal}
                        </Col>
                        <Col className="text-center" md={2}>
                          ($
                          {i.boxData[0].amountDifferen}
                          )
                        </Col>
                        <Col className="text-right" md={1}>
                          <Button
                            size="sm"
                            variant="info"
                            onClick={() => this.props.modifyAlert(i._id)}
                          >
                            <i className="text-right far fa-edit" />
                          </Button>
                        </Col>
                      </Row>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey={a}>
                      <ListGroup variant="flush">
                        {i.boxData[0].data.map((x) => {
                          const item = this.props.items.find((c) => c._id === x);
                          if (item !== undefined) {
                            return (
                              <ListGroup.Item key={Math.random()}>
                                <Row>
                                  <Col>
                                    <p className="font-weight-bold py-0">
                                      Concepto:
                                    </p>
                                    {item.concept}
                                  </Col>
                                  <Col>
                                    <p className="font-weight-bold py-0">
                                      Código:
                                    </p>
                                    {item.code}
                                  </Col>
                                  <Col
                                    className={
                                      `text-right ${
                                        item.type
                                          ? 'text-success'
                                          : 'text-danger'}`
                                    }
                                  >
                                    <p className="font-weight-bold py-0">
                                      Monto:
                                    </p>
                                    $
                                    {item.amount}
                                  </Col>
                                </Row>
                              </ListGroup.Item>
                            );
                          }
                          return (
                            <ListGroup.Item>
                              Este contenido fue eliminado
                            </ListGroup.Item>
                          );
                        })}
                      </ListGroup>
                    </Accordion.Collapse>
                  </>
                );
              })}
            </Card>
          </Accordion>
        </Modal.Body>
      </Modal>
    );
  }
}
