import React from 'react';
import { Container, Row, Button, Form, Modal } from 'react-bootstrap';
import axios from 'axios';
import { notify } from 'react-notify-toast';
import { useHistory } from 'react-router-dom';

export default function CrudProject(
  { client, show, onHide, projectDelete, updateClient }
) {
  const history = useHistory();
  const headerConfig = {
    headers: { 'auth-token': localStorage.getItem('JWT') },
  };
  const handleDelete = () => {
    let copyOfProjects = client.projects ? client.projects : [];
    copyOfProjects = projectDelete;
    axios
      .put(`/api/client/${client._id}`, { ...client, projects: copyOfProjects }, headerConfig)
      .then(({ data }) => {
        if (data.success) {
          updateClient();
          notify.show(data.message, 'success');
        } else {
          notify.show(data.message, 'error');
        }
      })
      .catch((err) => {
        notify.show(err.message, 'error');
        history.push('/');
      });
  };

  return (
    <Modal centered show={show} onHide={onHide}>
      <Form onSubmit={handleDelete}>
        <Modal.Header closeButton>
          <Modal.Title>Eliminación del proyecto</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row className="w-100">
              Estás a punto de borrar este proyecto.
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onHide}>
            Cerrar
          </Button>
          <Button variant="danger" type="submit">
            Eliminar
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
