const incomes = [
  'Rollingkiosc',
  'Merchandising',
  'Curso Full-stack',
  'Cursos MKT',
  'Otros',
  'Seña',
  'Aporte capital socios',
  'Caja intermedia',
  'Ingreso curso completo',
  'Ajustes',
  'Cuota',
];

const outcome = [
  'Ajustes',
  'Otros',
  'Retiro socios',
  'Mercadería kiosco',
  'Pago cuota cliente',
  'Servicios',
  'Compra hardware',
  'Impuestos',
  'Honorarios',
  'Mantenimiento site',
  'Librería e impresora',
  'Cumpleaños y eventos site',
  'Insumos de café',
  'Publicidad',
  'Materiales y equipamientos',
  'Retiro caja intermedia',
];

const clients = [
  'Stepmed',
  'Taxiar',
  'Fiat',
  'Colombia',
  'Rolling'
];

module.exports.incomes = incomes;
module.exports.outcome = outcome;
module.exports.clients = clients;
