/* eslint-disable react/jsx-boolean-value */
import React, { useContext } from 'react';
import { Navbar, Nav, OverlayTrigger, Popover } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import preval from 'preval.macro';
import { UserContext } from '../../context/UserContext';
import LinksNav from './LinksNav';
import UserAdministration from './UserAdministration';

const buildTimeStamp = preval`module.exports = new Date().toLocaleString()`;

function NavigationBar() {
  const user = useContext(UserContext);

  const popover = (
    <Popover id="build">
      <Popover.Title as="h3">Last Deploy - Date:</Popover.Title>
      <Popover.Content>
        {buildTimeStamp}
      </Popover.Content>
    </Popover>
  );

  return (
    <Navbar
      collapseOnSelect={true}
      expand="lg"
      bg="dark"
      variant="dark"
      className="mb-5"
    >
      <Link to="/">
        <OverlayTrigger
          trigger={['hover', 'focus']}
          placement="bottom"
          overlay={popover}
        >
          <Navbar.Brand className="text-dark text-decoration-none">
            <img
              src={`${process.env.PUBLIC_URL}/rclogowhite.png`}
              width="180"
              alt="RollingCode"
            />
            <p className="mb-2 lead text-center text-white">Cash Flow Tracking</p>
          </Navbar.Brand>
        </OverlayTrigger>
      </Link>
      <Navbar.Toggle />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="mr-auto">
          <LinksNav />
        </Nav>
        <UserAdministration />
      </Navbar.Collapse>
    </Navbar>
  );
}

export default NavigationBar;
