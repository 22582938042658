/* eslint-disable prefer-template */
import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignInAlt } from '@fortawesome/free-solid-svg-icons';
import LoadingScreen from '../../Layout/LoadingScreen';

export default function Home() {
  // Setting history
  const history = useHistory();

  // Setting state
  const [isLoading, setIsLoading] = useState(true);
  const [level, setLevel] = useState(9);

  // Checks if user is already logged in
  useEffect(() => {
    Axios.get('/api/user/checkUser', {
      headers: { 'auth-token': localStorage.getItem('JWT') },
    })
      .then(({ data }) => {
        setIsLoading(false);
        setLevel(data.level);
      })
      .catch((err) => {
        setIsLoading(false);
        setLevel(9);
      });
  }, []);

  // Printing UI
  if (isLoading) {
    return <LoadingScreen />;
  }
  if (level <= 2) {
    history.push('/bigbox');
    return null;
  }
  return (
    <Container>
      <Row>
        <Col className="text-center">
          <img
            src={process.env.PUBLIC_URL + '/rclogo.png'}
            alt="RollingCode School"
            width="500"
          />
          <p className="lead mt-4">
            Necesitas estar logueado para poder acceder
          </p>
          <Button
            variant="success"
            onClick={() => history.push('/users/login')}
          >
            <FontAwesomeIcon icon={faSignInAlt} />
            {' '}
            Ingresar
          </Button>
        </Col>
      </Row>
    </Container>
  );
}
