/* eslint-disable max-len */
import React, { Component } from 'react';
import Axios from 'axios';
import { notify } from 'react-notify-toast';
import { Form, Modal, Button } from 'react-bootstrap';
import moment from 'moment';
import { outcome } from '../../Layout/Categories';

export default class AddItemForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editItem: '',
      concept: '',
      detail: '',
      client: '',
      project: '',
      code: '',
      amount: '',
      type: true,
      isSaving: false,
      date: moment().format('YYYY-MM-DD'),
      paymentMethod: 'cash',
      numberOperation: '',
      calculatedIvaAmount: 21,
      calculatedIibbAmount: 3.5,
      calculatedIdcAmount: 0.6
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps !== this.props) {
      if (this.props.editId && this.props.editId !== '') {
        Axios.get(`/api/smallbox/get/single/${this.props.editId}/${this.props.currency}`, {
          headers: { 'auth-token': localStorage.getItem('JWT') },
        }).then((i) => {
          if (Object.keys(i.data).length && Object.keys(i.data.data).length) {
            if ((i.data.lenght !== 0) && (i.data.data[0].lenght !== 0)) {
              const data = i.data.data[0];
              data.date = moment.utc(data?.date).format('YYYY-MM-DD');
              this.setState({ ...data });
            } else {
              this.setState({
                editItem: '',
                concept: '',
                detail: '',
                client: '',
                code: '',
                amount: '',
                type: true,
                isSaving: false,
                date: moment().format('YYYY-MM-DD'),
                paymentMethod: 'cash',
                numberOperation: '',
                calculatedIvaAmount: 21,
                calculatedIibbAmount: 3.5,
                calculatedIdcAmount: 0.6
              });
            }
          } else {
            this.setState({
              editItem: '',
              concept: '',
              detail: '',
              client: '',
              code: '',
              amount: '',
              type: true,
              isSaving: false,
              date: moment().format('YYYY-MM-DD'),
              paymentMethod: 'cash',
              numberOperation: '',
              calculatedIvaAmount: 21,
              calculatedIibbAmount: 3.5,
              calculatedIdcAmount: 0.6
            });
          }
        });
      } else {
        this.setState({
          editItem: '',
          concept: '',
          detail: '',
          client: '',
          code: '',
          amount: '',
          type: true,
          isSaving: false,
          date: moment().format('YYYY-MM-DD'),
          paymentMethod: 'cash',
          numberOperation: '',
          calculatedIvaAmount: 21,
          calculatedIibbAmount: 3.5,
          calculatedIdcAmount: 0.6
        });
      }
    }
  }

  handleChange = async (obj) => {
    const { name, value, type, id } = obj.target;

    if (type === 'radio') {
      if (name === 'type') {
        if (this.state.type === true) {
          this.setState({
            calculatedIvaAmount: 21,
            calculatedIibbAmount: 3.5,
            calculatedIdcAmount: 0.6
          });
        }
        this.setState((i) => ({
          type: !i.type,
        }));
      } else {
        this.setState({ [name]: id });
      }
    } else if (name === 'calculatedIvaAmount' || name === 'calculatedIibbAmount' || name === 'calculatedIdcAmount') {
      this.setState({ [name]: parseFloat(value) });
    } else {
      this.setState({ [name]: value });
    }
  };

  onSubmit = (obj) => {
    obj.preventDefault();
    this.setState({ isSaving: true });

    const { concept, detail, client, code, amount, type, date, paymentMethod, numberOperation, calculatedIvaAmount, calculatedIibbAmount, calculatedIdcAmount } = this.state;

    if (this.props.editId) {
      Axios.put(
        `/api/smallbox/${this.props.editId}`,
        { concept, detail, client, code, amount, type, date, paymentMethod, numberOperation, calculatedIvaAmount, calculatedIibbAmount, calculatedIdcAmount, currency: this.props.currency },
        { headers: { 'auth-token': localStorage.getItem('JWT') } }
      )
        .then((res) => {
          notify.show(res.data.message, res.data.success ? 'success' : 'error');

          if (res.data.success) {
            this.props.refresh();
            this.setState({ isSaving: false });
            this.props.onHide();
          } else {
            this.setState({ isSaving: false });
          }
        })
        .catch((res) => {
          notify.show(res.data.message, res.data.success ? 'success' : 'error');
          this.setState({ isSaving: false });
        });
    } else {
      Axios.post(
        '/api/smallbox/post',
        { concept, detail, client, code, amount, type, date, paymentMethod, numberOperation, calculatedIvaAmount, calculatedIibbAmount, calculatedIdcAmount, currency: this.props.currency },
        { headers: { 'auth-token': localStorage.getItem('JWT') } }
      ).then((res) => {
        notify.show(res.data.message, res.data.success ? 'success' : 'error');

        if (res.data.success) {
          this.setState({ isSaving: false });
          this.props.refresh();
          this.props.onHide();
        } else {
          this.setState({ isSaving: false });
        }
      });
    }
  };

  render() {
    const { concept, detail, client, project, code, amount, type, date, paymentMethod, numberOperation, calculatedIvaAmount, calculatedIibbAmount, calculatedIdcAmount } = this.state;

    return (
      <Modal
        centered
        size="lg"
        show={this.props.show}
        onHide={this.props.onHide}
      >
        <Form onSubmit={this.onSubmit} autoComplete="off">
          <Modal.Header closeButton>
            <Modal.Title>Registrar movimiento</Modal.Title>
          </Modal.Header>

          <Modal.Body className="mx-5">
            <Form.Group controlId="concept">
              <Form.Label>Concepto:</Form.Label>
              <Form.Control
                required
                name="concept"
                type="text"
                max={25}
                value={concept}
                onChange={this.handleChange}
              />
            </Form.Group>

            <Form.Group controlId="date">
              <Form.Label>Fecha:</Form.Label>
              <Form.Control
                required
                name="date"
                type="date"
                value={date}
                onChange={this.handleChange}
              />
            </Form.Group>

            <Form.Group controlId="type">
              <Form.Label>Tipo: </Form.Label>
              <br />
              <Form.Check
                name="type"
                type="radio"
                label="Ingreso"
                id="type"
                value
                checked={Boolean(this.state.type) === true}
                onChange={this.handleChange}
                inline
              />
              <Form.Check
                name="type"
                type="radio"
                label="Egreso"
                id="type"
                value={false}
                checked={Boolean(this.state.type) === false}
                onChange={this.handleChange}
                inline
              />
            </Form.Group>

            <Form.Group controlId="client">
              <Form.Label>Cliente:</Form.Label>
              <Form.Control
                name="client"
                as="select"
                value={client}
                onChange={this.handleChange}
              >
                <option value="">Seleccione una opción...</option>
                {this.props.customers.map((i) => <option key={i.name}>{i.name}</option>)}
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="project">
              <Form.Label>Proyecto:</Form.Label>
              <Form.Control
                name="project"
                as="select"
                value={project}
                onChange={this.handleChange}
              >
                <option value="">Seleccione una opción...</option>
                {this.props.getProjects(this.state.client).map((i) => <option key={i.name}>{i.name}</option>)}
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="code">
              <Form.Label>Código:</Form.Label>
              <Form.Control
                required
                name="code"
                as="select"
                value={code}
                onChange={this.handleChange}
              >
                <option value="">Seleccione una opción...</option>
                {outcome.map((i) => <option key={i}>{i}</option>)}
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="calculatedIvaAmount">
              <Form.Label>% IVA:</Form.Label>
              <Form.Control
                required
                name="calculatedIvaAmount"
                as="select"
                value={calculatedIvaAmount}
                onChange={this.handleChange}
              >
                <option value={0}>0</option>
                <option value={10.5}>10.5</option>
                <option value={21}>21</option>
                <option value={27}>27</option>
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="calculatedIibbAmount">
              <Form.Label>% IIBB:</Form.Label>
              <Form.Control
                required
                name="calculatedIibbAmount"
                as="select"
                value={calculatedIibbAmount}
                onChange={this.handleChange}
              >
                <option value={0}>0</option>
                <option value={3.5}>3.5</option>
                <option value={5}>5</option>
              </Form.Control>
            </Form.Group>

            {
              type === false ? (
                <Form.Group controlId="calculatedIdcAmount">
                  <Form.Label>% IDC:</Form.Label>
                  <Form.Control
                    required
                    name="calculatedIdcAmount"
                    as="select"
                    value={calculatedIdcAmount}
                    onChange={this.handleChange}
                  >
                    <option value={0}>0</option>
                    <option value={0.6} selected={type === false}>0.6</option>
                  </Form.Control>
                </Form.Group>
              )
                :
                <></>
            }

            <Form.Group controlId="amount">
              <Form.Label>Monto:</Form.Label>
              <Form.Control
                required
                name="amount"
                min={1}
                step="0.01"
                type="number"
                value={amount}
                onChange={this.handleChange}
              />
            </Form.Group>

            <Form.Group controlId="detail">
              <Form.Label>Nota adicional:</Form.Label>
              <Form.Control
                name="detail"
                type="text"
                value={detail}
                onChange={this.handleChange}
              />
            </Form.Group>

            <Form.Group controlId="paymentMethod">
              <Form.Label>Método de pago: </Form.Label>
              <br />
              <Form.Check
                name="paymentMethod"
                type="radio"
                label="Efectivo"
                id="cash"
                checked={paymentMethod === 'cash'}
                onChange={this.handleChange}
                inline
              />
              <Form.Check
                name="paymentMethod"
                type="radio"
                label="Cheque"
                id="check"
                checked={paymentMethod === 'check'}
                onChange={this.handleChange}
                inline
              />
              <Form.Check
                name="paymentMethod"
                type="radio"
                label="MercadoPago"
                id="mercadoPago"
                checked={paymentMethod === 'mercadoPago'}
                onChange={this.handleChange}
                inline
              />
              <Form.Check
                name="paymentMethod"
                type="radio"
                label="Banco"
                id="bank"
                checked={paymentMethod === 'bank'}
                onChange={this.handleChange}
                inline
              />
              <Form.Check
                name="paymentMethod"
                type="radio"
                label="Otro"
                id="other"
                checked={paymentMethod === 'other'}
                onChange={this.handleChange}
                inline
              />
            </Form.Group>
            {
              paymentMethod === 'mercadoPago' || paymentMethod === 'bank' ? (
                <Form.Group controlId="concept">
                  <Form.Label>Número de operación:</Form.Label>
                  <Form.Control
                    name="numberOperation"
                    type="text"
                    max={25}
                    value={numberOperation}
                    onChange={this.handleChange}
                  />
                </Form.Group>
              )
                :
                <></>
            }

          </Modal.Body>

          <Modal.Footer>
            <Button variant="secondary" onClick={this.props.onHide}>
              Cerrar
            </Button>
            <Button
              variant="success"
              disabled={this.state.isSaving}
              type="submit"
            >
              Guardar cambios
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
}
