import Axios from 'axios';
import React, { useContext, useState } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { notify } from 'react-notify-toast';
import { useHistory, useParams } from 'react-router-dom';
import { UserContext } from '../../context/UserContext';

export default function ChangePassword() {
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');

  const history = useHistory();

  const { id } = useParams<{ id: string }>();

  const user = useContext(UserContext);

  // -----------------------------------

  const handleSubmit = (e: any) => {
    e.preventDefault();

    Axios.put(`/api/user/updateuser/${id}`, {
      password,
      firstLogin: false,
    }).then((res) => {
      notify.show('Contraseña cambiada con éxito', 'success');
      if (user.level === 3) {
        history.push('/teacher');
      } else {
        history.push('/users/2fa/generate');
      }
    });
  };

  return (
    <Container>
      <Row>
        <Col md="3" />
        <Col className="bg-light shadow mb-5 rounded p-4 text-right">
          <Row>
            <p>
              Bienvenid@
              {' '}
              <strong>{user.username}</strong>
              . Por favor, ingresa
              una nueva contraseña para continuar.
            </p>
          </Row>
          <Form onSubmit={handleSubmit} autoComplete="off">
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>Nueva contraseña:</Form.Label>
                  <Form.Control
                    type="password"
                    name="password"
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>Repita nueva contraseña:</Form.Label>
                  <Form.Control
                    type="password"
                    name="repeatPassword"
                    onChange={(e) => setRepeatPassword(e.target.value)}
                    required
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                {password !== repeatPassword ? (
                  <p className="text-danger text-left p-0 m-0">
                    Las contraseñas no coinciden.
                  </p>
                ) : null}
              </Col>
              <Col md="2" className="d-flex justify-content-end">
                <Button variant="primary" type="submit">
                  Ingresar
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
        <Col md="3" />
      </Row>
    </Container>
  );
}
