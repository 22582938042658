/* eslint-disable react/jsx-boolean-value */
import React, { useContext } from 'react';
import { Button, Form, FormControl, OverlayTrigger, Popover, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import preval from 'preval.macro';
import { UserContext } from '../../context/UserContext';

const buildTimeStamp = preval`module.exports = new Date().toLocaleString()`;
/**
  * Borra el 2FA del usuario actual
  */
const delete2FA = () => {
  if (window.confirm('Eliminar 2FA?')) {
    console.log('empty');
  }
};

function UserAdministration() {
  const user = useContext(UserContext);
  const userdata = JSON.parse(localStorage.getItem('userdata') || '{}');
  return (
    <Navbar expand="lg" collapseOnSelect={true} bg="dark" variant="dark">
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ml-auto">
          {user.username ? (
            <NavDropdown title={`Usuario: ${user.username}`} id="Users">
              <NavDropdown.Item>
                <Link
                  className="text-dark text-decoration-none"
                  to={`/users/pswrdChange/${user.userId}`}
                >
                  Cambiar contraseña
                </Link>
              </NavDropdown.Item>
              {!userdata.has2FA ? (
                <NavDropdown.Item href="/users/2fa/generate">
                  Agregar 2Factor Auth.
                </NavDropdown.Item>
              ) : user.hasVerified2FA ? (
                <NavDropdown.Item onClick={delete2FA}>
                  Eliminar 2FA
                </NavDropdown.Item>
              ) : null}
              <NavDropdown.Item>
                <Link className="text-dark text-decoration-none" to="/users/logout">
                  Cerrar Sesión
                </Link>
              </NavDropdown.Item>
            </NavDropdown>
          ) : (
            <Link className="nav-link" to="/users/login">
              Iniciar sesión
            </Link>
          )}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default UserAdministration;
