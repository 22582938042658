import React, { Component } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import Axios from 'axios';
import { notify } from 'react-notify-toast';

export default class modifyConfirm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      confirm: true,
      amountExternal: 0,
      amountInternal: 0,
      reason: '',
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps !== this.props) {
      if (this.props.modifyId) {
        Axios.get(
          `/api/smallbox/getSingleCloseMovement/${this.props.modifyId}`,
          {
            headers: { 'auth-token': localStorage.getItem('JWT') },
          }
        ).then((i) => {
          console.clear();

          this.setState({ ...i.data.data[0].boxData[0] });
        });
      } else {
        this.setState({
          confirm: true,
          amountExternal: 0,
          amountInternal: 0,
          reason: '',
        });
      }
    }
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  onSubmit = (e) => {
    e.preventDefault();

    Axios.put(
      `/api/smallbox/editCloseMovement/${this.props.modifyId}/0`,
      this.state,
      {
        headers: { 'auth-token': localStorage.getItem('JWT') },
      }
    ).then((i) => {
      if (i.data.success) {
        notify.show(i.data.message, 'success');
        this.props.refresh();
        this.props.onHide();
      } else {
        notify.show(i.data.message, 'error');
      }
    });
  };

  render() {
    if (this.state.confirm) {
      return (
        <Modal show={this.props.show} onHide={this.props.onHide}>
          <Modal.Header closeButton>
            <Modal.Title>Editar movimiento</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Vas a editar un cierre de caja. Esto puede ser penalizado si se lo
            realiza de manera incorrecta. ¿Desea Continuar?
          </Modal.Body>
          <Modal.Footer>
            <Button variant="info" onClick={this.props.onHide}>
              Cancelar
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                this.setState((i) => ({ confirm: !i.confirm }));
              }}
            >
              Aceptar
            </Button>
          </Modal.Footer>
        </Modal>
      );
    }
    return (
      <Modal show={this.props.show} onHide={this.props.onHide}>
        <Form onSubmit={this.onSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>Editar movimiento</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group>
              <Form.Label>Monto Interno</Form.Label>
              <Form.Control
                value={this.state.amountInternal}
                type="number"
                disabled
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>
                Monto Externo
                <small className="danger text-italic text-danger">*</small>
              </Form.Label>
              <Form.Control
                onChange={this.handleChange}
                value={this.state.amountExternal}
                type="number"
                name="amountExternal"
                required
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>
                Motivo de edición
                <small className="danger text-italic text-danger">*</small>
              </Form.Label>
              <Form.Control
                onChange={this.handleChange}
                value={this.state.reason}
                type="text"
                name="reason"
                required
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="info" onClick={this.props.onHide}>
              Cancelar
            </Button>
            <Button variant="primary" type="submit">
              Aceptar
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
}
