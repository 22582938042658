import React, { useEffect, useContext } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { notify } from 'react-notify-toast';
import { UserContext } from '../../context/UserContext';
import LoadingScreen from '../../Layout/LoadingScreen';

export default function Logout() {
  const history = useHistory();
  const { logout } = useContext(UserContext);

  useEffect(() => {
    localStorage.clear();
    setTimeout(() => {
      logout();
      notify.show('Te has deslogueado con éxito!', 'success');
      history.push('/');
    }, 3000);
    // eslint-disable-next-line
  }, []);

  return (
    <Container>
      <Row>
        <Col>
          <LoadingScreen customText="Saliendo del sistema" />
        </Col>
      </Row>
    </Container>
  );
}
