import React, { Component, createContext } from 'react';
import Axios from 'axios';

export const UserContext = createContext();

export class UserProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      isLoggedIn: '',
      id: '',
      has2FA: false,
      hasVerified2FA: false,
      level: 0,
      pendingGenerate: false,
      qr64: ''
    };
  }

  componentDidMount() {
    if (localStorage.getItem('JWT')) {
      if (localStorage.getItem('userdata')) {
        try {
          const a = JSON.parse(localStorage.getItem('userdata'));
          this.setState(a);
        } catch (err) {
          localStorage.clear();
        }
        this.setState({ isLoggedIn: true });
      }
    } else {
      localStorage.clear();
      this.setState({
        level: 0,
      });
    }

    this.checkForValidUser();
  }

  checkForValidUser = () => {
    if (localStorage.getItem('JWT')) {
      Axios.get('/api/user/checkUser', {
        headers: { 'auth-token': localStorage.getItem('JWT') },
      }).then((response) => {
        this.setState({
          level: response.data.level,
        });
      });
    }
  };

  setData = (username, id, isLoggedIn, level, has2FA, pendingGenerate, qr64) => {
    this.setState({ username, id, isLoggedIn, level, has2FA, pendingGenerate, qr64 });
  };

  logout = () => {
    this.setState({
      username: '',
      id: '',
      isLoggedIn: false,
      level: 0,
      has2FA: false,
      hasVerified2FA: false,
      pendingGenerate: false,
      qr64: ''
    });
  };

  render() {
    return (
      <UserContext.Provider
        value={{
          setData: (username, id, isLoggedIn, level, has2FA, pendingGenerate, qr64) =>
            this.setData(username, id, isLoggedIn, level, has2FA, pendingGenerate, qr64),
          username: this.state.username,
          isLoggedIn: this.state.isLoggedIn,
          level: this.state.level,
          userId: this.state.id,
          has2FA: this.state.has2FA,
          pendingGenerate: this.state.pendingGenerate,
          qr64: this.state.qr64,
          logout: this.logout,
          hasVerified2FA: this.state.hasVerified2FA,
          verify2FA: (e) => {
            this.setState({ hasVerified2FA: e });
          },
        }}
      >
        {this.props.children}
      </UserContext.Provider>
    );
  }
}
