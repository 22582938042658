/* eslint-disable max-len */
/* eslint-disable no-else-return */
/* eslint-disable consistent-return */
import React, { useContext } from 'react';
import { Redirect, Route, useHistory } from 'react-router-dom';
import { UserContext } from '../../context/UserContext';

const PrivateRoute = ({ auth, component: Component, ...rest }: any) => {
  const { isLoggedIn, has2FA, hasVerified2FA, pendingGenerate, qr64, username } = useContext(UserContext);

  // console.log(isLoggedIn, has2FA, hasVerified2FA, pendingGenerate, 'pnd', qr64, 'logs log');

  const history = useHistory();

  return (
    <Route
      {...rest}
      render={
        (props: any) => {
          if (username === 'TEST BigBox') {
            return <Component {...props} />;
          } else if ((isLoggedIn && has2FA && hasVerified2FA)) {
            return <Component {...props} />;
          } else if (isLoggedIn && !has2FA) {
            return <Redirect to="/users/2fa/generate" />;
          } else if (!isLoggedIn) {
            if (history.location.pathname === '/users/login' || history.location.pathname === '/users/2fa/verify') {
              return <Redirect to="/" />;
            }
          } else if (pendingGenerate) {
            return <Redirect to="/users/2fa/generate" />;
          } else if (isLoggedIn && has2FA && !hasVerified2FA) {
            return <Redirect to="/users/2fa/verify" />;
          }
        }
        //   if (isLoggedIn && localStorage.getItem("JWT")) {
        //   } else {
      }
    />
  );
};

export default PrivateRoute;
