import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

export default class LoadingScreen extends Component {
  render() {
    return (
      <Container className={this.props.className}>
        <Row className="ls-row">
          <Col />
          <Col md={2} className="my-auto shadow bg-light rounded py-4">
            <div className="m-auto ls-div">
              <div className="spinner" />
              <p className="m-0 p-0 strong rclogo-center">{'< >'}</p>
            </div>
            <h1 className="mt-3 lead text-gray text-center">
              {this.props.customText
                ? this.props.customText
                : 'Cargando datos...'}
            </h1>
          </Col>
          <Col />
        </Row>
      </Container>
    );
  }
}
