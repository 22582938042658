import { InputInterface } from '../../../interfaces/InputInterface';

export const PROYECT: InputInterface[] = [
  {
    id: 0,
    title: 'Nombre',
    name: 'name',
    placeholder: 'Ingrese el nombre aquí',
    type: 'text',
    extended: true,
    required: true,
  },
  {
    id: 1,
    title: 'Fecha de Inicio',
    name: 'startDate',
    placeholder: 'Ingrese la fecha de inicio',
    type: 'date',
    extended: true,
    required: true,
    value: new Date(),
    step: 0.01,
  },
  {
    id: 2,
    title: 'Monto Total',
    name: 'totalAmount',
    placeholder: 'Ingrese el monto aquí',
    type: 'number',
    extended: true,
    required: true,
    value: 0,
    min: '0',
  },
  {
    id: 3,
    title: 'Cuotas',
    name: 'installments',
    placeholder: 'Ingrese las cuotas aquí',
    type: 'number',
    extended: true,
    required: true,
    value: 0,
    min: '0',
  },
  {
    id: 4,
    title: 'Comentarios',
    name: 'commentary',
    placeholder: 'Ingrese el comentario aquí',
    type: 'text',
    extended: true,
    as: 'textarea',
  },
];
