/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
/* eslint-disable no-lonely-if */
import React, { useState, useEffect } from 'react';
import { Pagination } from 'react-bootstrap';

export default function PaginationBar(props) {
  // Setting state
  const [pager, setPager] = useState({
    totalPages: 0,
    startIndex: 0,
    endIndex: 0,
    currentPage: 1,
    pages: [],
  });

  // Set selected page
  const setPage = (page) => {
    const { items, pageSize } = props;
    let newPager = pager;

    if (page < 1 || page > newPager.totalPages) {
      return;
    }
    // get new pager object for specified page
    newPager = getPager(items.length, page, pageSize);

    // get new page of items from items array
    const pageOfItems = props.items.length ? items.slice(newPager.startIndex, newPager.endIndex + 1) : [];

    // update state
    setPager(newPager);

    // call change page function in parent component
    props.onChangePage(pageOfItems);
  };

  // Component did mount / update
  useEffect(
    () => {
      // set page if items array isn't empty
      if (props.items) {
        setPage(props.initialPage);
      }
    },
    [props.items, props.hasToRefresh],
  );

  const getPager = (totalItems, currentPage, pageSize) => {
    // default to first page
    currentPage = currentPage || 1;

    // default page size is 10
    pageSize = pageSize || 10;

    // calculate total pages
    const totalPages = Math.ceil(totalItems / pageSize);

    let startPage; let
      endPage;
    if (totalPages <= 10) {
      // less than 10 total pages so show all
      startPage = 1;
      endPage = totalPages;
    } else {
      // more than 10 total pages so calculate start and end pages
      if (currentPage <= 6) {
        startPage = 1;
        endPage = 10;
      } else if (currentPage + 4 >= totalPages) {
        startPage = totalPages - 9;
        endPage = totalPages;
      } else {
        startPage = currentPage - 5;
        endPage = currentPage + 4;
      }
    }

    // calculate start and end item indexes
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

    // create an array of pages to ng-repeat in the pager control
    const pages = [...Array(endPage + 1 - startPage).keys()].map((i) => startPage + i);

    // return object with all pager properties required by the view
    return {
      totalItems,
      currentPage,
      pageSize,
      totalPages,
      startPage,
      endPage,
      startIndex,
      endIndex,
      pages,
    };
  };

  if (!pager.pages || pager.pages.length <= 1) {
    return null;
  }
  return (
    <Pagination className="m-0 p-0">
      <Pagination.First disabled={pager.currentPage === 1} onClick={() => setPage(1)} />
      <Pagination.Prev disabled={pager.currentPage === 1} onClick={() => setPage(pager.currentPage - 1)} />
      {pager.pages.map((page, index) => (
        <Pagination.Item onClick={() => setPage(page)} active={pager.currentPage === page} key={index}>
          {page}
        </Pagination.Item>
      ))}
      <Pagination.Next
        disabled={pager.currentPage === pager.totalPages}
        onClick={() => setPage(pager.currentPage + 1)}
      />
      <Pagination.Last
        disabled={pager.currentPage === pager.totalPages}
        onClick={() => setPage(pager.totalPages)}
      />
    </Pagination>
  );
}
