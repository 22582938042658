import { InputInterface } from '../../../interfaces/InputInterface';

export const FORM: InputInterface[] = [
  {
    id: 0,
    title: 'Nombre',
    name: 'name',
    placeholder: 'Ingrese el nombre aquí',
    type: 'text',
    extended: true,
  },
  {
    id: 1,
    title: 'E-Mail',
    name: 'email',
    placeholder: 'Ingrese el E-Mail aquí',
    type: 'text',
    extended: true,
  },
  {
    id: 2,
    title: 'Teléfono',
    name: 'phone',
    placeholder: 'Ingrese el teléfono aquí',
    type: 'number',
    extended: true,
  },
  {
    id: 3,
    title: 'Activo/Finalizado',
    name: 'isActive',
    type: 'select',
    extended: true,
    options: [
      {
        name: 'Seleccione la actividad de este cliente...', value: '', hidden: true, key: 0,
      },
      {
        name: 'Activo', value: 'active', key: 1,
      },
      {
        name: 'Finalizado', value: 'down', key: 2,
      }
    ]
  },
  {
    id: 4,
    title: 'Comentarios',
    name: 'commentary',
    placeholder: 'Ingrese el comentario aquí',
    type: 'text',
    extended: true,
    as: 'textarea',
  },
];
