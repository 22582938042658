import Axios from 'axios';
import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { notify } from 'react-notify-toast';
import { UserContext } from '../../context/UserContext';
import { State, loggedInUser } from '../../Types/Login';

export default function Login() {
  const history = useHistory();
  const user = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState<Partial<State>>({
    username: '',
    password: '',
    id: '',
    response: {},
  });

  const handleChange = (obj: any) => {
    const { name, value } = obj.target;
    setState({
      ...state,
      [name]: value,
    });
  };

  /**
   *
   * Redirige al usuario según corresponda
   *
   *    @param message Mensaje a mostrar si falla o el logueo es exitoso
   *    @param has2fa Token de Google Authenticator
   *    @param firstLogin Booleano que determina si llevarte a la pantalla de Password o no
   *    @param level Nivel de accesso, 1-Admin, 2-Comunicaciones, 3-Mentor
   *    @param _id id del usuario logueado exitosamente
   */
  const redirectUser = ({
    message,
    has2fa,
    firstLogin,
    level,
    _id,
    pendingGenerate
  }: loggedInUser) => {
    setTimeout(() => {
      notify.show(message, 'success');
      if (has2fa) {
        if (pendingGenerate) {
          history.push('/users/2fa/generate');
        } else {
          history.push('/bigbox');
        }
      } else if (firstLogin) {
        history.push(`/users/pswrdChange/${_id}`);
      } else if (level === 3) {
        history.push('/teacher');
      } else {
        history.push('/bigbox');
      }
      setIsLoading(false);
    }, 2000);
  };

  /**
   * Guarda datos al localStorage para la persistencia
   * de sesión
   *
   * @param auth Token de JWT para el login
   * @param data Objeto de respuesta del login (See backend)
   */
  const saveToLocalStorage = (auth: string, data: any) => {
    localStorage.setItem('JWT', auth);
    localStorage.setItem(
      'userdata',
      JSON.stringify({
        username: data.username,
        id: data._id,
        has2FA: data.has2fa,
        pendingGenerate: data.pendingGenerate,
        qr64: data.qr64,
      })
    );
  };

  const onSubmit = (e: any) => {
    const { username, password } = state;

    e.preventDefault();
    setIsLoading(true);

    Axios.post('/api/user/login', { email: username, password })
      .then((res) => {
        setState({ id: res.data._id, response: res });
        saveToLocalStorage(res.headers.auth, res.data);
        user.setData(
          res.data.username,
          res.data._id,
          true,
          res.data.level,
          res.data.has2fa,
          res.data.pendingGenerate,
          res.data.qr64,
        );
        redirectUser(res.data);
      })
      .catch((err) => {
        notify.show(err.response.data.message, 'error');
        setIsLoading(false);
      });
  };

  return (
    <Container>
      <Row>
        <Col md="3" />
        <Col className="border-gray mb-5 bg-light shadow rounded p-4">
          <Form onSubmit={onSubmit} autoComplete="off">
            <Row>
              <Col>
                <Form.Group controlId="formBasicEmail">
                  <Form.Label>Dirección de correo</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="usuario@dominio.com"
                    name="username"
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group controlId="formBasicPassword">
                  <Form.Label>Contraseña</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="*************"
                    name="password"
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col className="d-flex justify-content-end">
                <Button variant="primary" type="submit" disabled={isLoading}>
                  {isLoading ? 'Iniciando sesión... ' : 'Ingresar'}
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
        <Col md="3" />
      </Row>
    </Container>
  );
}
