import React, { Component } from 'react';
import { Form, Modal, Button } from 'react-bootstrap';
import moment from 'moment';
import Axios from 'axios';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

export default class DownloadExcel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      since: undefined,
      to: undefined,
    };
  }

  handleSubmit = (e) => {
    e.preventDefault();

    Axios.get(
      `api/smallbox/getDay/${
        this.state.since
          ? moment(this.state.since).startOf('day').format('YYYY-MM-DD')
          : moment('2020-01-01').startOf('day').format('YYYY-MM-DD')
      }/${
        this.state.to
          ? moment(this.state.to).endOf('day').format('YYYY-MM-DD')
          : moment().endOf('day').format('YYYY-MM-DD')
      }`,
      {
        headers: { 'auth-token': localStorage.getItem('JWT') },
      }
    ).then(({ data }) => {
      const fileType =
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const fileExtension = '.xlsx';
      const ws = XLSX.utils.json_to_sheet(data.data);
      const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const output = new Blob([excelBuffer], { type: fileType });

      FileSaver.saveAs(
        output,
        `Cierre de caja - ${
          this.state.since
            ? moment(this.state.since).startOf('day').format('YYYY-MM-DD') +
              this.state.to
              ? moment(this.state.to).startOf('day').format('YYYY-MM-DD')
              : ''
            : moment().startOf('day').format('YYYY-MM-DD')
        }${fileExtension}`
      );

      this.props.onHide();
    });
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  render() {
    return (
      <Modal show={this.props.show} onHide={this.props.onHide}>
        <Form onSubmit={this.handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>Descargar Excel</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              Seleccione el rango de tiempo que desea descargar
              {' '}
              <i className="text-muted">(deja en blanco para descargar todo)</i>
            </p>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Desde:</Form.Label>
              <Form.Control
                type="date"
                name="since"
                onChange={this.handleChange}
                value={this.state.since}
              />
            </Form.Group>

            <Form.Group controlId="formBasicPassword">
              <Form.Label>Hasta:</Form.Label>
              <Form.Control
                type="date"
                name="to"
                onChange={this.handleChange}
                value={this.state.to}
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.props.onHide}>
              Cerrar
            </Button>
            <Button variant="primary" type="submit">
              Descargar
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
}
