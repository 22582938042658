// Importando librerías externas
import React from 'react';
import { Row, Col, Button, Form, InputGroup } from 'react-bootstrap';

export default function Selector({
  clientSelected,
  confirmDelete,
  addClient,
  clientList,
  clientListLoading,
}) {
  return (
    <Row className="m-0">
      <div className="container d-flex bg-light rounded shadow p-3 align-items-center">
        <Col className="" xs={8} sm={8} md={8} lg={8}>
          <Row>
            <InputGroup className="m-0 p-0">
              <InputGroup.Prepend>
                <InputGroup.Text>Clientes:</InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                as="select"
                name="clientSelected"
                onChange={(obj) => clientSelected(obj)}
                disabled={clientListLoading || clientList.length === 0}
              >
                {clientListLoading || clientList.length === 0 ? (
                  <option hidden>Cargando clientes</option>
                ) : (
                  <option value="" hidden>
                    Seleccione un cliente...
                  </option>
                )}
                {clientList.map((i, a) => (
                  <option key={a} value={i._id} name={i.name}>
                    {i.name}
                  </option>
                ))}
              </Form.Control>
            </InputGroup>
          </Row>
        </Col>
        <Col>
          <Button
            className="mr-2 mb-0"
            variant="danger"
            onClick={() => confirmDelete()}
          >
            <i className="fas fa-times-circle mr-2" />
            Eliminar cliente
          </Button>
          <Button
            className="mb-0"
            variant="success"
            onClick={() => addClient()}
          >
            <i className="fas fa-plus-circle mr-2" />
            Agregar cliente
          </Button>
        </Col>
      </div>
    </Row>
  );
}
