import Home from '../../screens/Home/Home';
import Login from '../../screens/userAuth/Login';
import Logout from '../../screens/userAuth/Logout';
import Generate2FA from '../../screens/userAuth/2FA/Generate2FA';
import Verify2FA from '../../screens/userAuth/2FA/Verify2FA';
import ChangePassword from '../../screens/userAuth/ChangePassword';
import SelectorSmallbox from '../../screens/bigbox/SelectorSmallbox';
import Clients from '../../screens/crud/clients/Clients';

export const PUBLIC_ROUTES = [
  {
    id: 'public0',
    path: '/',
    component: Home,
  },
  {
    id: 'public1',
    path: '/users/login',
    component: Login,
  },
  {
    id: 'public2',
    path: '/users/pswrdChange/:id',
    component: ChangePassword
  },
  {
    id: 'public3',
    path: '/users/logout',
    component: Logout
  },
  {
    id: 'public4',
    path: '/users/2fa/generate',
    component: Generate2FA
  },
  {
    id: 'public5',
    path: '/users/2fa/verify',
    component: Verify2FA
  },
];

export const PRIVATE_ROUTES = [
  {
    id: 'private0',
    path: '/bigbox',
    component: SelectorSmallbox,
    dataExtra: 'testeo'
  },
  {
    id: 'private1',
    path: '/clients',
    component: Clients,
    dataExtra: 'testeo'
  },
];
