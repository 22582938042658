import React from 'react';
import { Link } from 'react-router-dom';

export default function MentorNav() {
  return (
    <>
      <Link className="nav-link mr-auto" to="/bigbox">
        Caja grande
      </Link>
      <Link className="nav-link mr-auto" to="/clients">
        Clientes
      </Link>
    </>
  );
}
