// Importando librerías externas
import React, { useEffect } from 'react';
import { Container, Row, Col, Button, Form, Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { notify } from 'react-notify-toast';
import { useHistory } from 'react-router-dom';
import { PROYECT } from './constants';
import CustomInput from '../../../components/CustomInput';

export default function Project(
  { client, setClient, show, onHide, edit, setEdit, indexEdit, projectEdit, setProjectEdit }
) {
  const { register, watch, handleSubmit, setValue, reset } = useForm();
  const history = useHistory();
  const headerConfig = {
    headers: { 'auth-token': localStorage.getItem('JWT') },
  };
  const handleProject = () => {
    if (edit) {
      const copyOfProjects = client.projects ? client.projects : [];
      copyOfProjects[indexEdit] = watch();
      axios
        .put(`/api/client/${client._id}`, { ...client, projects: copyOfProjects }, headerConfig)
        .then(({ data }) => {
          setEdit(false);
          if (data.success) {
            notify.show(data.message, 'success');
          } else {
            notify.show(data.message, 'error');
          }
        })
        .catch((err) => {
          notify.show(err.message, 'error');
          history.push('/');
        });
    } else {
      // eslint-disable-next-line prefer-const
      let copyOfProjects = client.projects ? client.projects : [];
      copyOfProjects?.push(watch());
      setClient({ ...client, projects: copyOfProjects });
      onHide();
    }
  };

  useEffect(() => {
    // eslint-disable-next-line array-callback-return
    Object.keys(projectEdit).map((key) => {
      if (key !== 'projects' && key !== '_id') {
        setValue(key, projectEdit[key]);
      }
    });
    // }
  }, [projectEdit]);

  const clearData = () => {
    Object.keys({
      name: '',
      setValue: '',
      startDate: new Date(),
      totalAmount: 0,
      installments: 0,
      commentary: ''
      // eslint-disable-next-line array-callback-return
    }).map((key) => {
      if (key !== 'projects' && key !== '_id') {
        reset(key, projectEdit[key]);
      }
    });
    onHide({});
  };

  return (
    <Modal centered show={show} onHide={() => clearData()}>
      <Form onSubmit={handleSubmit(handleProject)}>
        <Modal.Body>
          <Container>
            <p className="lead">Configuración del proyecto</p>
            <Row className="w-100">
              {PROYECT.map((form) => (
                <Col sm="12" md={form.extended ? '12' : '4'}>
                  <CustomInput
                    id={form.id}
                    title={form.title}
                    name={form.name}
                    type={form.type}
                    placeholder={form.placeholder}
                    customText={form.customText}
                    register={register}
                    watch={watch}
                    step={form.step}
                    options={form.options}
                    as={form.as}
                    min={form.min}
                    required={form.required}
                  />
                </Col>
              ))}
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => clearData()}>
            Cerrar
          </Button>
          <Button variant="info" type="submit">
            Guardar
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
