import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { PUBLIC_ROUTES, PRIVATE_ROUTES } from './constants';
import PrivateRoute from '../../screens/userAuth/PrivateRoute';

export default function Routing() {
  return (
    <Switch>
      {PUBLIC_ROUTES.map((route) => (
        <Route
          key={route.id}
          path={route.path}
          component={route.component}
          exact
        />
      ))}
      {PRIVATE_ROUTES.map((route) => (
        <PrivateRoute
          key={route.id}
          path={route.path}
          dataExtra={route.dataExtra}
          component={route.component}
          exact
        />
      )
      )}
    </Switch>
  );
}
