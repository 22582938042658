/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
import Axios from 'axios';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { notify } from 'react-notify-toast';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { UserContext } from '../../../context/UserContext';

export default function Verify2FA() {
  const [inputCode, setInputCode] = useState('');
  const [disabled, setDisabled] = useState(false);
  const { verify2FA } = useContext(UserContext);
  const history = useHistory();

  const verifyCode = (obj: any) => {
    obj.preventDefault();
    setDisabled(true);
    Axios.post(
      '/api/user/2fa/verify',
      { token: inputCode },
      {
        headers: { 'auth-token': localStorage.getItem('JWT') },
      }
    )
      .then((response) => {
        setDisabled(false);
        if (response.data.success) {
          verify2FA(true);
          // if (history.location.pathname === "/users/login" || history.location.pathname === "/users/2fa/verify") {
          history.push('/');
          // }
        } else {
          notify.show(response.data.message || 'No se ha podido validar el token', 'error');
        }
      })
      .catch(({ response }) => {
        setDisabled(false);
        notify.show(response.data.message || 'No se ha podido validar el token', 'error');
      });
  };

  return (
    <Container>
      <Row className="d-flex align-items-center   text-center">
        <Col className="w-100 bg-white shadow rounded p-3 mb-5 mx-2">
          <h1 className="display-4">Verificá tu identidad</h1>
          <p className="lead">
            Ingresá desde tu celular a "Google Authenticator" e ingresá el
            codigo bajo el ítem que dice "RollingCode - Cash Flow Tracking"
          </p>

          <Row>
            <Col />
            <Col>
              <Form className="mt-5" onSubmit={verifyCode}>
                <Form.Group>
                  <Form.Label>Token</Form.Label>
                  <Form.Control
                    value={inputCode}
                    onChange={(e) => setInputCode(e.target.value)}
                    type="number"
                    placeholder="Token de Google Authenticator"
                  />
                </Form.Group>

                <Button variant="primary" type="submit" disabled={disabled}>
                  Verificar
                </Button>
              </Form>
            </Col>
            <Col />
          </Row>
        </Col>
      </Row>
    </Container>
  );
}
