/* eslint-disable max-len */
import React from 'react';
import { Form } from 'react-bootstrap';
import { InputInterface } from '../../interfaces/InputInterface';
import { ObjectsInterface } from '../../interfaces/ObjectsInterface';

export default function CustomInput(
  { id, key, type, title, name, placeholder, customText, register, options, disabled, step, as, min, required }: InputInterface
) {
  return (
    <>
      {type === 'select' ?
        (
          <Form.Group controlId="exampleForm.SelectCustom">
            <Form.Label>{title}</Form.Label>
            <Form.Control
              key={id}
              disabled={disabled}
              as="select"
              custom
              {...register(name, { required: true })}
            >
              {options && options.map((option: ObjectsInterface) => <option value={option.value} hidden={option.hidden} key={option.key}>{option.name}</option>)}
            </Form.Control>
          </Form.Group>
        ) : (
          <Form.Group controlId={String(id)}>
            <Form.Label>{title}</Form.Label>
            <Form.Control
              key={id}
              type={type}
              placeholder={placeholder}
              step={step}
              as={as}
              min={min}
              required={required}
              {...register(name, { required: true })}
            />
            {customText && (
              <Form.Text className="text-muted">
                {customText}
              </Form.Text>
            )}
          </Form.Group>
        )}
    </>
  );
}
