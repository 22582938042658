/* eslint-disable prefer-const */
import React, { Component } from 'react';
import { Row, Col, Form, Button, InputGroup } from 'react-bootstrap';
import Axios from 'axios';
import { outcome } from '../../Layout/Categories';

export default class SearchBox extends Component {
  constructor(props) {
    super(props);

    this.state = {
      concept: '',
      code: '',
      client: '',
      type: '',
      dateFrom: '',
      dateTo: '',
      currency: this.props.currency
    };
  }

  handleChange = (e) => {
    const { name, value } = e.target;

    this.setState({ [name]: value });
  };

  onSubmit = (e) => {
    if (e) {
      e.preventDefault();
    }
    Axios.post('/api/smallbox/search', this.state, {
      headers: { 'auth-token': localStorage.getItem('JWT') },
    }).then(({ data }) => this.props.setResults(data));
  };

  clean = () => {
    this.props.refresh();
    this.setState({
      concept: '',
      code: '',
      client: '',
      type: '',
      dateFrom: '',
      dateTo: '',
      currency: this.props.currency
    }, () => this.onSubmit());
  };

  render() {
    let customersNames = this.props.customers.map((i) => i.name);

    return (
      <Form onSubmit={this.onSubmit}>
        <Row className="mt-3 p-3 bg-light shadow rounded">
          <Col md={9}>
            <Row>
              <Form.Group as={Col}>
                <InputGroup size="sm">
                  <InputGroup.Prepend>
                    <InputGroup.Text>Concepto:</InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control
                    type="text"
                    value={this.state.concept}
                    placeholder="Creador / Concepto"
                    onChange={this.handleChange}
                    name="concept"
                  />
                </InputGroup>
              </Form.Group>

              <Form.Group as={Col}>
                <InputGroup size="sm">
                  <InputGroup.Prepend>
                    <InputGroup.Text>Cliente:</InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control
                    as="select"
                    value={this.state.client}
                    onChange={this.handleChange}
                    name="client"
                  >
                    <option value="" hidden>Seleccione una opción...</option>
                    {customersNames.map((i) => (
                      <option key={i}>{i}</option>
                    ))}
                  </Form.Control>
                </InputGroup>
              </Form.Group>

              <Form.Group as={Col}>
                <InputGroup size="sm">
                  <InputGroup.Prepend>
                    <InputGroup.Text>Código:</InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control
                    as="select"
                    value={this.state.code}
                    onChange={this.handleChange}
                    name="code"
                  >
                    <option value="" hidden>Seleccione una opción...</option>
                    {outcome.map((i) => (
                      <option key={i}>{i}</option>
                    ))}
                  </Form.Control>
                </InputGroup>
              </Form.Group>

            </Row>

            <Row>
              <Form.Group as={Col}>
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text>Desde:</InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control
                    type="date"
                    value={this.state.dateFrom}
                    onChange={this.handleChange}
                    name="dateFrom"
                  />
                </InputGroup>
              </Form.Group>

              <Form.Group as={Col}>
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text>Hasta:</InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control
                    type="date"
                    value={this.state.dateTo}
                    onChange={this.handleChange}
                    name="dateTo"
                  />
                </InputGroup>
              </Form.Group>

              <Col>
                <Form.Group
                  as={Row}
                  className="text-center border border-gray py-2 px-1"
                >
                  <Form.Check
                    inline
                    type="radio"
                    name="type"
                    checked={this.state.type === 'true'}
                    onChange={this.handleChange}
                    value="true"
                    label="Ingresos"
                  />
                  <Form.Check
                    inline
                    type="radio"
                    name="type"
                    checked={this.state.type === 'false'}
                    onChange={this.handleChange}
                    value="false"
                    label="Egresos"
                  />
                  <Form.Check
                    inline
                    type="radio"
                    name="type"
                    checked={this.state.type === ''}
                    onChange={this.handleChange}
                    value=""
                    label="Todos"
                  />
                </Form.Group>
              </Col>
            </Row>
          </Col>
          <Col md={3} className="text-center my-auto">
            <Button type="submit" variant="info">
              Buscar
            </Button>
            <Button onClick={this.clean} className="ml-3 my-3" variant="primary">
              Limpiar búsqueda
            </Button>
          </Col>
        </Row>
      </Form>
    );
  }
}
